import loadable from "@loadable/component";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../constants/EndPoint";
import DataLoadSpinner from "../../components/Reusable/Spinner";
import usePatchHook from "../../customHooks/usePatchHook";
import { useState } from "react";

const ProfileComponent = loadable(() => import("../../components/Profile"));

const Profile = () => {
  const [openUpdate, setOpenUpdate] = useState(false);

  const { data: profileData, isLoading } = useGetHook({
    queryKey: ["profile"],
    url: `${APIS.PROFILE}`,
    params: {},
  });
  const { mutateAsync, isPostSuccess } = usePatchHook({
    queryKey: ["profile"],
  });

  const handleProfileUpdate = async (data: any) => {
    const { dob_english, ...rest } = data;
    const formData = dob_english ? { ...rest, dob_english } : rest;
    try {
      await mutateAsync({
        url: `profile/${profileData?.profile?.id}/`,
        formData: formData,
      });
      setOpenUpdate(!openUpdate);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {isLoading ? (
        <>
          <DataLoadSpinner />
        </>
      ) : (
        <ProfileComponent
          profileData={profileData}
          handleProfileUpdate={handleProfileUpdate}
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
        />
      )}
    </>
  );
};

export default Profile;
