import { NavLink } from "react-router-dom";
import APIS from "../../constants/EndPoint";
import useGetHook from "../../customHooks/useGetHook";
import loadable from "@loadable/component";
import DataLoadSpinner from "../../components/Reusable/Spinner";

const DashboardComponent = loadable(
  () => import("../../components/Dashboard/Dashboard")
);
const Dashboard = () => {
  const { data: services, isLoading } = useGetHook({
    queryKey: ["services"],
    url: `${APIS.SERVICES}`,
    params: {},
  });
  return (
    <>
      {isLoading ? (
        <DataLoadSpinner />
      ) : (
        <>
          <DashboardComponent services={services} />
        </>
      )}
    </>
  );
};

export default Dashboard;
