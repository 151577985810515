import React from "react";

interface SpinnerProps {}

const DataLoadSpinner: React.FC<SpinnerProps> = (props) => {
  return (
    <div
      className={
        "w-10 h-10 border-4 border-blue-900 border-double rounded-full animate-spin m-auto mt-10"
      }
    ></div>
  );
};

export default DataLoadSpinner;
