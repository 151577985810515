import React from "react";
import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";

type Props = {};
const ChangePasswordComponent = loadable(
  () => import("../../components/ChangePassword")
);

const ChangePassword = (props: Props) => {
  const { mutateAsync } = usePostHook({
    queryKey: ["profile"],
    navigateURL: "/auth-login",
  });

  const handleChangePassword = async (data: any) => {
    try {
      await mutateAsync({
        url: "/user/change-password/",
        formData: data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <ChangePasswordComponent handleChangePassword={handleChangePassword} />
    </div>
  );
};

export default ChangePassword;
