import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { patchApiData } from "../helpers/axiosInstance";

interface IUsePatchHook {
  queryKey: string[];
  navigateURL?: string;
}

const usePatchHook = (props: IUsePatchHook) => {
  const { queryKey, navigateURL } = props;
  const queryClient: any = useQueryClient();
  const navigate = useNavigate();

  const {
    isLoading: isPostLoading,
    mutateAsync,
    isSuccess: isPostSuccess,
    data,
    isError,
  } = useMutation({
    mutationFn: patchApiData,
    onSuccess: (data) => {
      if (data.status === 200) {
        toast.success("Updated successfully");
        if (navigateURL) {
          navigate(navigateURL);
        }
      } else {
        toast.error("Some things went wrongs");
      }
    },
    onError: (error) => {
      toast.error("Some things went wrongs");
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey ?? null);
    },
  });
  return {
    isPostLoading,
    mutateAsync,
    isPostSuccess,
    data,
    isError,
  };
};

export default usePatchHook;
