import { classNames } from "../utils/classNames";
import ServiceIcon from "../assets/icons/services.svg"
import ClientIcon from "../assets/icons/clients.svg"
import { NavLink, Outlet, useNavigate } from "react-router-dom";
//@ts-ignore
import { Layout } from '@dibtech/styleguide'

const navigation: any = [
  { name: 'Service', href: '/', icon: ServiceIcon },
  { name: 'Billing', href: '/billing', icon: ClientIcon },
]

const DetailDashboardLayout = () => {
  return (
    <Layout
      children={<Outlet />}
      navigationComponent={<nav className="px-2 space-y-1">
        {navigation?.map((item: any) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={classNames(
              item.current ? 'bg-indigo-800 text-white' : 'text-black gap-2 hover:bg-[#5B6AB0] hover:text-white',
              'group flex items-center px-2 py-2 text-base font-medium rounded-md'
            )}
          >
            <img src={item.icon} alt="" />
            {item.name}
          </NavLink>
        ))}
      </nav>
      } />
  )
}


export default DetailDashboardLayout