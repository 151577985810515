import { createBrowserRouter } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import DetailDashboardLayout from "../layouts/DetailDashboardLayout";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";
import Billing from "../pages/Billing/Billing";
import ChangePassword from "../pages/ChangePassword";

const router = createBrowserRouter([
  {
    element: (
      <PrivateRoute>
        <DetailDashboardLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/billing",
        element: <Billing />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/change-password",
        element: <ChangePassword />,
      },
    ],
  },
]);

export default router;
