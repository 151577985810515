import React from 'react'
import loadable from '@loadable/component'


const BillingComponent = loadable(() => import('../../components/Billing/Billing'))

const Billing = () => {
  return (
    <BillingComponent/>
  )
}

export default Billing